import './App.css';
import React, { useState, useEffect } from 'react';
import poster from "./pbb/sold_out.png"
import { Icon, Loader, Dimmer } from 'semantic-ui-react'

import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import "./App.css";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
const stripePromise = loadStripe("pk_live_51LS5iVEyfU2a4uI9leTXDW6Xy9QKkLRhCvzdrTztyczc9Lr3NDdVLDotqK2HL9IFx6dt0zwJ4j8MUWJMFIaOHIgT009wdKYGBo");

const CheckoutForm = (props) => {
  const [clientSecret, setClientSecret] = useState('');
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Create a Checkout Session as soon as the page loads
    fetch("https://dubs-backend.herokuapp.com/bday-create-session?price=" + props.price + "&email=" + props.email + "&team=" + props.team, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.clientSecret)
        setLoading(false)
      });
  }, [props.email, props.price, props.team]);

  return (
    (loading ? (
      <Dimmer active inverted style={{backgroundColor: "#ebd5b4"}} >
        <Loader size='big'/>
      </Dimmer>
    ):(
      <div id="checkout" className='checkout'>
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{clientSecret}}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
    ))
    
  )
}

const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    fetch(`https://dubs-backend.herokuapp.com/session-status?session_id=${sessionId}`)
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
      });
  }, []);

  if (status === 'open') {
    return (
      <Navigate to="/checkout" />
    )
  }

  if (status === 'complete') {
    return (
      <>
            <img src={poster} alt="Poster" style={{overflowY:"hidden", width:"80%"}}/>
            <h2 style={{fontFamily: "Bell MT Semi Bold"}}>
            Stay tuned for the location...
            </h2>
            <h3 style={{fontFamily: "Bell MT Semi Bold"}}>
            A confirmation email will be sent to {customerEmail}.
            </h3>
            <h3 style={{fontFamily: "Bell MT Semi Bold"}}>
            If you have any questions, please email <a className='a' href="mailto:help@dubsadvising.com">help@dubsadvising.com</a>.
            </h3>
          </>
    )
  }

  return null;
}


function RegistrationForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    plusOne: 'none', // Changed to represent the dropdown selection
    team: ''
  });

  const [formSuccess, setFormSuccess] = useState(false)

  // const validateEmail = () => {
  //   if (!formData.email.endsWith('mail.utoronto.ca')) {
  //     alert('Email must end with "mail.utoronto.ca"')
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       email: "",}))
  //       return false;
  //   }
  //   else if (formData.plusOne === "none"){
  //     alert('Please select a status option');
  //     return false;
  //   }
  //   return true;
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSuccess(true)
  };

  return (

     formSuccess ? (
      <CheckoutForm price={formData.plusOne} email={formData.email} team={formData.team}/>
    ):(
      <>
    <p style={{marginTop: "45vh"}}>
      men's water polo presents:
    </p>
    <p>
      a varsity mixer...
    </p>
    <Icon name="angle double down" size="big"/>
    <br style={{marginBottom: "45vh"}} ></br>
<img src={poster} alt="Poster" style={{overflowY:"hidden", width:"80%", marginTop: "50vh"}}/>
<h2 style={{fontFamily: "Bell MT Semi Bold"}}>
  pavle's birthday bash
</h2>
<h3 style={{fontFamily: "Bell MT Semi Bold"}} >feb 17th</h3>
<h3 style={{fontFamily: "Bell MT Semi Bold"}} >doors open 10pm</h3>
<h3 style={{fontFamily: "Bell MT Semi Bold"}} >location: to be revealed</h3>
<div className='form-container'>
      <form onSubmit={handleSubmit} className='form'>
        <div className='form-group'>
          <label htmlFor="name" className='form-label'></label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className='form-input'
            placeholder='Name'
            disabled
            tooltip="Unable to purchase tickets at this time"
          />
        </div>
      <div className='form-wrap'>
      <div className='form-group' >
        <label htmlFor="email" className='form-label'></label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          className='email-input'
          placeholder='Email'
          disabled
          tooltip="Unable to purchase tickets at this time"
        />
      </div>
      <div className='form-group' >
          <label htmlFor="plusOne"className='form-label'></label>
          <select
            name="plusOne"
            id="plusOne"
            value={formData.plusOne}
            onChange={handleChange}
            className='form-select'
            disabled
            tooltip="Unable to purchase tickets at this time"
          >
            <option value="none">Status</option>
            <option value="varsity">Varsity</option>
            <option value="varsityPlus">Friends of Varsity</option>
            {/* <option value="other">General Public</option> */}
          </select>
      </div>
      { formData.plusOne === "varsity" ? (
        <div className="form-group" >
          <label htmlFor="team"className='form-label'></label>
          <input
            type="team"
            id="team"
            name="team"
            value={formData.team}
            onChange={handleChange}
            required
            className='form-input'
            placeholder='Varsity Team'
            disabled
            tooltip="Unable to purchase tickets at this time"
          />
        </div>
      ):null
      }
      <button type="submit" className="form-button" disabled={true}></button>
      </div>
    </form>
    </div>
      </>
    )    
  );
}


function App() {
  return (
    <div className='App'>
      <Router>
        <Routes>
          <Route path="/" element={<RegistrationForm />} />
          <Route path="/return" element={<Return />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
